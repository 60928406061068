<template>
  <div
    data-component-name="WrapperOverlay"
    :class="{ opened, blur }"
    :style="{
      backgroundColor: COLORS[accent],
    }"
  >
    <transition name="fade">
      <slot v-if="opened" />
    </transition>
  </div>
</template>

<script setup lang="ts">
type Accents = 'light' | 'dark';

const props = withDefaults(
  defineProps<{
    opened?: boolean
    accent?: Accents
    blur?: boolean
  }>(),
  {
    opened: false,
    accent: 'light',
    blur: false,
  },
);

const COLORS: Record<Accents, string> = {
  light: 'rgba(38, 11, 64, 0.16)',
  dark: 'rgba(33, 37, 41, 0.67)',
};

useHead({
  bodyAttrs: {
    class: {
      'no-scroll': () => props.opened,
    },
  },
});
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-in-out;
  transform: scale(1) translateY(0);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scale(0.95) translateY(20px);
}

[data-component-name="WrapperOverlay"] {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2147483631;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  padding: 0.75rem 1.75rem 0.69rem 1.75rem;

  &.opened {
    pointer-events: auto;
    opacity: 1;

    &.blur {
      backdrop-filter: blur(12px);
    }
  }
}
</style>
